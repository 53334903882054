import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type AddNewPollInput = {
  color: Scalars['String'];
  desc: Scalars['String'];
  imgSrc: Scalars['String'];
  name: Scalars['String'];
  progressColor: Scalars['String'];
};

export type Answer = {
  __typename?: 'Answer';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  question: Question;
  questionId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  value: Scalars['String'];
};

export type AnswerInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  value: Scalars['String'];
};

export type CreateFolderType = {
  __typename?: 'CreateFolderType';
  name: Scalars['String'];
  path: Scalars['String'];
  success: Scalars['Boolean'];
};

export type GetMembersOutput = {
  __typename?: 'GetMembersOutput';
  currentMember: Members;
  nextMember: NextPrevMemberItem;
  previousMember: NextPrevMemberItem;
};

export type HomeDataReturn = {
  __typename?: 'HomeDataReturn';
  countActivePolls: Scalars['Float'];
  countActiveVoters: Scalars['Float'];
  countAnswers: Scalars['Float'];
};

export type MemberInput = {
  company?: InputMaybe<Scalars['String']>;
  companyLink?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  imgUrl?: InputMaybe<Scalars['String']>;
  isBoard: Scalars['Boolean'];
  isHidden?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  logos?: InputMaybe<Array<Scalars['String']>>;
  longDesc?: InputMaybe<Scalars['String']>;
  memberCategory?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  shortDesc?: InputMaybe<Scalars['String']>;
};

export type Members = {
  __typename?: 'Members';
  company: Scalars['String'];
  companyLink?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  imgUrl: Scalars['String'];
  isBoard: Scalars['Boolean'];
  isHidden: Scalars['Boolean'];
  lastName: Scalars['String'];
  logos?: Maybe<Array<Scalars['String']>>;
  longDesc: Scalars['String'];
  memberCategory: Scalars['String'];
  role: Scalars['String'];
  shortDesc: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addNewPoll: Poll;
  authVoter: Scalars['String'];
  changeUserStatus: Scalars['Boolean'];
  completeVote: Scalars['Boolean'];
  draftPoll: Scalars['Boolean'];
  login?: Maybe<User>;
  logout: Scalars['Boolean'];
  publishPoll: Scalars['Boolean'];
  removeAnswer: Scalars['Boolean'];
  removeFiles: Scalars['Boolean'];
  removeFolder: Scalars['Boolean'];
  removeMember: Scalars['Boolean'];
  removeQuestion: Scalars['Boolean'];
  removeSendedAnswer: Scalars['Boolean'];
  sendAnswer: Scalars['Boolean'];
  subscribeEmail: Scalars['Boolean'];
  updateAnswer: Answer;
  updateMember: Members;
  updateQuestion: Question;
};

export type MutationAddNewPollArgs = {
  data: AddNewPollInput;
};

export type MutationAuthVoterArgs = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  pollSlug: Scalars['String'];
};

export type MutationChangeUserStatusArgs = {
  email: Scalars['String'];
};

export type MutationCompleteVoteArgs = {
  slug: Scalars['String'];
};

export type MutationDraftPollArgs = {
  pollId: Scalars['String'];
};

export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type MutationPublishPollArgs = {
  pollId: Scalars['String'];
};

export type MutationRemoveAnswerArgs = {
  answerId: Scalars['String'];
};

export type MutationRemoveFilesArgs = {
  publicIds: Array<Scalars['String']>;
};

export type MutationRemoveFolderArgs = {
  folderName: Scalars['String'];
};

export type MutationRemoveMemberArgs = {
  id: Scalars['String'];
};

export type MutationRemoveQuestionArgs = {
  questionId: Scalars['String'];
};

export type MutationRemoveSendedAnswerArgs = {
  data: RemoveAnswerInput;
};

export type MutationSendAnswerArgs = {
  data: SendAnswerInput;
};

export type MutationSubscribeEmailArgs = {
  email: Scalars['String'];
};

export type MutationUpdateAnswerArgs = {
  data: UpdateAnswerInput;
};

export type MutationUpdateMemberArgs = {
  data: UpdateMemberInput;
};

export type MutationUpdateQuestionArgs = {
  data: UpdateQuestionInput;
};

export type NextPrevMemberItem = {
  __typename?: 'NextPrevMemberItem';
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
};

export type Poll = {
  __typename?: 'Poll';
  color: Scalars['String'];
  createdAt: Scalars['DateTime'];
  desc: Scalars['String'];
  id: Scalars['ID'];
  imgSrc: Scalars['String'];
  isPublished: Scalars['Boolean'];
  name: Scalars['String'];
  owner: User;
  progressColor: Scalars['String'];
  questions?: Maybe<Array<Question>>;
  slug: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  votes?: Maybe<Array<Vote>>;
};

export type Query = {
  __typename?: 'Query';
  createFolder: CreateFolderType;
  downloadVotes: Poll;
  getAllMembers: Array<Members>;
  getAllPolls: Array<Poll>;
  getAllUsers: Array<User>;
  getAllVoters: Array<Voter>;
  getAllVotes: Array<Scalars['String']>;
  getMembers: GetMembersOutput;
  getPoll: Poll;
  getStatisticsVotes: Array<Statistics>;
  getVote: Vote;
  getVotes: Array<Vote>;
  hasUserSubmittedPoll: Scalars['Boolean'];
  homeData: HomeDataReturn;
  me: User;
  meVoter: Voter;
  signRequest: SignatureType;
};

export type QueryCreateFolderArgs = {
  folderName: Scalars['String'];
};

export type QueryDownloadVotesArgs = {
  pollId: Scalars['String'];
};

export type QueryGetAllMembersArgs = {
  slug: Scalars['String'];
};

export type QueryGetAllUsersArgs = {
  order?: InputMaybe<Scalars['String']>;
};

export type QueryGetAllVotersArgs = {
  order?: InputMaybe<Scalars['String']>;
  pollId?: InputMaybe<Scalars['String']>;
};

export type QueryGetAllVotesArgs = {
  pollSlug: Scalars['String'];
};

export type QueryGetMembersArgs = {
  id: Scalars['String'];
};

export type QueryGetPollArgs = {
  slug: Scalars['String'];
};

export type QueryGetStatisticsVotesArgs = {
  pollSlug: Scalars['String'];
};

export type QueryGetVoteArgs = {
  answerId: Scalars['String'];
};

export type QueryGetVotesArgs = {
  pollSlug: Scalars['String'];
};

export type QueryHasUserSubmittedPollArgs = {
  pollId: Scalars['String'];
  voterId: Scalars['String'];
};

export type QuerySignRequestArgs = {
  params?: InputMaybe<SignRequestInput>;
};

export type Question = {
  __typename?: 'Question';
  answers: Array<Answer>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isRequired: Scalars['Boolean'];
  poll: Poll;
  pollId: Scalars['String'];
  questionName: Scalars['String'];
  typeAnswer: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type QuestionInput = {
  id: Scalars['String'];
  isRequired: Scalars['Boolean'];
  questionName: Scalars['String'];
  typeAnswer: Scalars['String'];
};

export type RemoveAnswerInput = {
  answerId: Scalars['String'];
  typeOfAnswer: Scalars['String'];
};

export type SendAnswerInput = {
  answerId: Scalars['String'];
  typeOfAnswer: Scalars['String'];
  value: Scalars['String'];
};

export type SignatureType = {
  __typename?: 'SignatureType';
  signature: Scalars['String'];
  timestamp: Scalars['Float'];
};

export type Statistics = {
  __typename?: 'Statistics';
  answerId: Scalars['String'];
  count: Scalars['Float'];
};

export type UpdateMemberInput = {
  member: MemberInput;
};

export type User = {
  __typename?: 'User';
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  polls: Array<Poll>;
  status: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

export type Vote = {
  __typename?: 'Vote';
  answer: Answer;
  answerId: Scalars['String'];
  answerValue: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  poll: Poll;
  pollId: Scalars['String'];
  question: Question;
  questionId: Scalars['String'];
  status: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
  voter: Voter;
  voterId: Scalars['String'];
};

export type Voter = {
  __typename?: 'Voter';
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  votes: Array<Vote>;
};

export type SignRequestInput = {
  folder?: InputMaybe<Scalars['String']>;
};

export type UpdateAnswerInput = {
  answer: AnswerInput;
  questionId: Scalars['String'];
};

export type UpdateQuestionInput = {
  pollId: Scalars['String'];
  question: QuestionInput;
};

export type RemoveAnswerMutationVariables = Exact<{
  answerId: Scalars['String'];
}>;

export type RemoveAnswerMutation = {
  __typename?: 'Mutation';
  removeAnswer: boolean;
};

export type UpdateAnswerMutationVariables = Exact<{
  data: UpdateAnswerInput;
}>;

export type UpdateAnswerMutation = {
  __typename?: 'Mutation';
  updateAnswer: {
    __typename?: 'Answer';
    id: string;
    name: string;
    value: string;
  };
};

export type DownloadVotesQueryVariables = Exact<{
  pollId: Scalars['String'];
}>;

export type DownloadVotesQuery = {
  __typename?: 'Query';
  downloadVotes: {
    __typename?: 'Poll';
    name: string;
    votes?: Array<{
      __typename?: 'Vote';
      answerValue: string;
      status: boolean;
      voter: {
        __typename?: 'Voter';
        id: string;
        firstName: string;
        lastName: string;
        email: string;
      };
      answer: { __typename?: 'Answer'; name: string; value: string };
      question: {
        __typename?: 'Question';
        typeAnswer: string;
        questionName: string;
      };
    }> | null;
  };
};

export type GetStatisticsVotesQueryVariables = Exact<{
  pollSlug: Scalars['String'];
}>;

export type GetStatisticsVotesQuery = {
  __typename?: 'Query';
  getStatisticsVotes: Array<{
    __typename?: 'Statistics';
    answerId: string;
    count: number;
  }>;
};

export type SignRequestQueryVariables = Exact<{
  params?: InputMaybe<SignRequestInput>;
}>;

export type SignRequestQuery = {
  __typename?: 'Query';
  signRequest: {
    __typename?: 'SignatureType';
    timestamp: number;
    signature: string;
  };
};

export type GetHomeDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetHomeDataQuery = {
  __typename?: 'Query';
  homeData: {
    __typename?: 'HomeDataReturn';
    countAnswers: number;
    countActivePolls: number;
    countActiveVoters: number;
  };
};

export type RemoveFilesMutationVariables = Exact<{
  publicIds: Array<Scalars['String']> | Scalars['String'];
}>;

export type RemoveFilesMutation = {
  __typename?: 'Mutation';
  removeFiles: boolean;
};

export type RemoveFolderMutationVariables = Exact<{
  folderName: Scalars['String'];
}>;

export type RemoveFolderMutation = {
  __typename?: 'Mutation';
  removeFolder: boolean;
};

export type CreateFolderQueryVariables = Exact<{
  folderName: Scalars['String'];
}>;

export type CreateFolderQuery = {
  __typename?: 'Query';
  createFolder: {
    __typename?: 'CreateFolderType';
    success: boolean;
    path: string;
    name: string;
  };
};

export type RemoveMemberMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type RemoveMemberMutation = {
  __typename?: 'Mutation';
  removeMember: boolean;
};

export type UpdateMemberMutationVariables = Exact<{
  data: UpdateMemberInput;
}>;

export type UpdateMemberMutation = {
  __typename?: 'Mutation';
  updateMember: {
    __typename?: 'Members';
    firstName: string;
    lastName: string;
    role: string;
    company: string;
    companyLink?: string | null;
    isHidden: boolean;
    memberCategory: string;
    shortDesc: string;
    longDesc: string;
    imgUrl: string;
    logos?: Array<string> | null;
    isBoard: boolean;
  };
};

export type GetAllMembersQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type GetAllMembersQuery = {
  __typename?: 'Query';
  getAllMembers: Array<{
    __typename?: 'Members';
    id: string;
    firstName: string;
    lastName: string;
    role: string;
    company: string;
    companyLink?: string | null;
    isHidden: boolean;
    memberCategory: string;
    shortDesc: string;
    longDesc: string;
    imgUrl: string;
    logos?: Array<string> | null;
    isBoard: boolean;
  }>;
};

export type AddNewPollMutationVariables = Exact<{
  data: AddNewPollInput;
}>;

export type AddNewPollMutation = {
  __typename?: 'Mutation';
  addNewPoll: { __typename?: 'Poll'; slug: string };
};

export type DraftPollMutationVariables = Exact<{
  pollId: Scalars['String'];
}>;

export type DraftPollMutation = { __typename?: 'Mutation'; draftPoll: boolean };

export type PublishPollMutationVariables = Exact<{
  pollId: Scalars['String'];
}>;

export type PublishPollMutation = {
  __typename?: 'Mutation';
  publishPoll: boolean;
};

export type GetAllPollsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllPollsQuery = {
  __typename?: 'Query';
  getAllPolls: Array<{
    __typename?: 'Poll';
    id: string;
    name: string;
    imgSrc: string;
    desc: string;
    slug: string;
    isPublished: boolean;
    createdAt: any;
    updatedAt: any;
    owner: { __typename?: 'User'; firstName: string; lastName: string };
  }>;
};

export type GetPollQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type GetPollQuery = {
  __typename?: 'Query';
  getPoll: {
    __typename?: 'Poll';
    id: string;
    name: string;
    slug: string;
    isPublished: boolean;
    createdAt: any;
    updatedAt: any;
    questions?: Array<{
      __typename?: 'Question';
      id: string;
      questionName: string;
      typeAnswer: string;
      isRequired: boolean;
      answers: Array<{
        __typename?: 'Answer';
        id: string;
        name: string;
        value: string;
      }>;
    }> | null;
  };
};

export type RemoveQuestionMutationVariables = Exact<{
  questionId: Scalars['String'];
}>;

export type RemoveQuestionMutation = {
  __typename?: 'Mutation';
  removeQuestion: boolean;
};

export type UpdateQuestionMutationVariables = Exact<{
  data: UpdateQuestionInput;
}>;

export type UpdateQuestionMutation = {
  __typename?: 'Mutation';
  updateQuestion: { __typename?: 'Question'; id: string };
};

export type ChangeUserStatusMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type ChangeUserStatusMutation = {
  __typename?: 'Mutation';
  changeUserStatus: boolean;
};

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;

export type LoginMutation = {
  __typename?: 'Mutation';
  login?: {
    __typename?: 'User';
    firstName: string;
    lastName: string;
    email: string;
  } | null;
};

export type LogoutMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutMutation = { __typename?: 'Mutation'; logout: boolean };

export type GetAllUsersQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllUsersQuery = {
  __typename?: 'Query';
  getAllUsers: Array<{
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    status: boolean;
    createdAt: any;
    updatedAt: any;
  }>;
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  };
};

export type GetAllVotersQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllVotersQuery = {
  __typename?: 'Query';
  getAllVoters: Array<{
    __typename?: 'Voter';
    firstName: string;
    lastName: string;
    email: string;
    createdAt: any;
    updatedAt: any;
    votes: Array<{
      __typename?: 'Vote';
      id: string;
      voterId: string;
      status: boolean;
      poll: { __typename?: 'Poll'; slug: string };
    }>;
  }>;
};

export const RemoveAnswerDocument = gql`
  mutation removeAnswer($answerId: String!) {
    removeAnswer(answerId: $answerId)
  }
`;
export type RemoveAnswerMutationFn = Apollo.MutationFunction<
  RemoveAnswerMutation,
  RemoveAnswerMutationVariables
>;

/**
 * __useRemoveAnswerMutation__
 *
 * To run a mutation, you first call `useRemoveAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAnswerMutation, { data, loading, error }] = useRemoveAnswerMutation({
 *   variables: {
 *      answerId: // value for 'answerId'
 *   },
 * });
 */
export function useRemoveAnswerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveAnswerMutation,
    RemoveAnswerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveAnswerMutation,
    RemoveAnswerMutationVariables
  >(RemoveAnswerDocument, options);
}
export type RemoveAnswerMutationHookResult = ReturnType<
  typeof useRemoveAnswerMutation
>;
export type RemoveAnswerMutationResult =
  Apollo.MutationResult<RemoveAnswerMutation>;
export type RemoveAnswerMutationOptions = Apollo.BaseMutationOptions<
  RemoveAnswerMutation,
  RemoveAnswerMutationVariables
>;
export const UpdateAnswerDocument = gql`
  mutation updateAnswer($data: updateAnswerInput!) {
    updateAnswer(data: $data) {
      id
      name
      value
    }
  }
`;
export type UpdateAnswerMutationFn = Apollo.MutationFunction<
  UpdateAnswerMutation,
  UpdateAnswerMutationVariables
>;

/**
 * __useUpdateAnswerMutation__
 *
 * To run a mutation, you first call `useUpdateAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAnswerMutation, { data, loading, error }] = useUpdateAnswerMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateAnswerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAnswerMutation,
    UpdateAnswerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAnswerMutation,
    UpdateAnswerMutationVariables
  >(UpdateAnswerDocument, options);
}
export type UpdateAnswerMutationHookResult = ReturnType<
  typeof useUpdateAnswerMutation
>;
export type UpdateAnswerMutationResult =
  Apollo.MutationResult<UpdateAnswerMutation>;
export type UpdateAnswerMutationOptions = Apollo.BaseMutationOptions<
  UpdateAnswerMutation,
  UpdateAnswerMutationVariables
>;
export const DownloadVotesDocument = gql`
  query downloadVotes($pollId: String!) {
    downloadVotes(pollId: $pollId) {
      name
      votes {
        answerValue
        status
        voter {
          id
          firstName
          lastName
          email
        }
        answer {
          name
          value
        }
        question {
          typeAnswer
          questionName
        }
      }
    }
  }
`;

/**
 * __useDownloadVotesQuery__
 *
 * To run a query within a React component, call `useDownloadVotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadVotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadVotesQuery({
 *   variables: {
 *      pollId: // value for 'pollId'
 *   },
 * });
 */
export function useDownloadVotesQuery(
  baseOptions: Apollo.QueryHookOptions<
    DownloadVotesQuery,
    DownloadVotesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DownloadVotesQuery, DownloadVotesQueryVariables>(
    DownloadVotesDocument,
    options
  );
}
export function useDownloadVotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DownloadVotesQuery,
    DownloadVotesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DownloadVotesQuery, DownloadVotesQueryVariables>(
    DownloadVotesDocument,
    options
  );
}
export type DownloadVotesQueryHookResult = ReturnType<
  typeof useDownloadVotesQuery
>;
export type DownloadVotesLazyQueryHookResult = ReturnType<
  typeof useDownloadVotesLazyQuery
>;
export type DownloadVotesQueryResult = Apollo.QueryResult<
  DownloadVotesQuery,
  DownloadVotesQueryVariables
>;
export const GetStatisticsVotesDocument = gql`
  query getStatisticsVotes($pollSlug: String!) {
    getStatisticsVotes(pollSlug: $pollSlug) {
      answerId
      count
    }
  }
`;

/**
 * __useGetStatisticsVotesQuery__
 *
 * To run a query within a React component, call `useGetStatisticsVotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatisticsVotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatisticsVotesQuery({
 *   variables: {
 *      pollSlug: // value for 'pollSlug'
 *   },
 * });
 */
export function useGetStatisticsVotesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetStatisticsVotesQuery,
    GetStatisticsVotesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetStatisticsVotesQuery,
    GetStatisticsVotesQueryVariables
  >(GetStatisticsVotesDocument, options);
}
export function useGetStatisticsVotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStatisticsVotesQuery,
    GetStatisticsVotesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetStatisticsVotesQuery,
    GetStatisticsVotesQueryVariables
  >(GetStatisticsVotesDocument, options);
}
export type GetStatisticsVotesQueryHookResult = ReturnType<
  typeof useGetStatisticsVotesQuery
>;
export type GetStatisticsVotesLazyQueryHookResult = ReturnType<
  typeof useGetStatisticsVotesLazyQuery
>;
export type GetStatisticsVotesQueryResult = Apollo.QueryResult<
  GetStatisticsVotesQuery,
  GetStatisticsVotesQueryVariables
>;
export const SignRequestDocument = gql`
  query signRequest($params: signRequestInput) {
    signRequest(params: $params) {
      timestamp
      signature
    }
  }
`;

/**
 * __useSignRequestQuery__
 *
 * To run a query within a React component, call `useSignRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignRequestQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSignRequestQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SignRequestQuery,
    SignRequestQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SignRequestQuery, SignRequestQueryVariables>(
    SignRequestDocument,
    options
  );
}
export function useSignRequestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SignRequestQuery,
    SignRequestQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SignRequestQuery, SignRequestQueryVariables>(
    SignRequestDocument,
    options
  );
}
export type SignRequestQueryHookResult = ReturnType<typeof useSignRequestQuery>;
export type SignRequestLazyQueryHookResult = ReturnType<
  typeof useSignRequestLazyQuery
>;
export type SignRequestQueryResult = Apollo.QueryResult<
  SignRequestQuery,
  SignRequestQueryVariables
>;
export const GetHomeDataDocument = gql`
  query getHomeData {
    homeData {
      countAnswers
      countActivePolls
      countActiveVoters
    }
  }
`;

/**
 * __useGetHomeDataQuery__
 *
 * To run a query within a React component, call `useGetHomeDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHomeDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHomeDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHomeDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetHomeDataQuery,
    GetHomeDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetHomeDataQuery, GetHomeDataQueryVariables>(
    GetHomeDataDocument,
    options
  );
}
export function useGetHomeDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetHomeDataQuery,
    GetHomeDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetHomeDataQuery, GetHomeDataQueryVariables>(
    GetHomeDataDocument,
    options
  );
}
export type GetHomeDataQueryHookResult = ReturnType<typeof useGetHomeDataQuery>;
export type GetHomeDataLazyQueryHookResult = ReturnType<
  typeof useGetHomeDataLazyQuery
>;
export type GetHomeDataQueryResult = Apollo.QueryResult<
  GetHomeDataQuery,
  GetHomeDataQueryVariables
>;
export const RemoveFilesDocument = gql`
  mutation removeFiles($publicIds: [String!]!) {
    removeFiles(publicIds: $publicIds)
  }
`;
export type RemoveFilesMutationFn = Apollo.MutationFunction<
  RemoveFilesMutation,
  RemoveFilesMutationVariables
>;

/**
 * __useRemoveFilesMutation__
 *
 * To run a mutation, you first call `useRemoveFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFilesMutation, { data, loading, error }] = useRemoveFilesMutation({
 *   variables: {
 *      publicIds: // value for 'publicIds'
 *   },
 * });
 */
export function useRemoveFilesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveFilesMutation,
    RemoveFilesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveFilesMutation, RemoveFilesMutationVariables>(
    RemoveFilesDocument,
    options
  );
}
export type RemoveFilesMutationHookResult = ReturnType<
  typeof useRemoveFilesMutation
>;
export type RemoveFilesMutationResult =
  Apollo.MutationResult<RemoveFilesMutation>;
export type RemoveFilesMutationOptions = Apollo.BaseMutationOptions<
  RemoveFilesMutation,
  RemoveFilesMutationVariables
>;
export const RemoveFolderDocument = gql`
  mutation removeFolder($folderName: String!) {
    removeFolder(folderName: $folderName)
  }
`;
export type RemoveFolderMutationFn = Apollo.MutationFunction<
  RemoveFolderMutation,
  RemoveFolderMutationVariables
>;

/**
 * __useRemoveFolderMutation__
 *
 * To run a mutation, you first call `useRemoveFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFolderMutation, { data, loading, error }] = useRemoveFolderMutation({
 *   variables: {
 *      folderName: // value for 'folderName'
 *   },
 * });
 */
export function useRemoveFolderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveFolderMutation,
    RemoveFolderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveFolderMutation,
    RemoveFolderMutationVariables
  >(RemoveFolderDocument, options);
}
export type RemoveFolderMutationHookResult = ReturnType<
  typeof useRemoveFolderMutation
>;
export type RemoveFolderMutationResult =
  Apollo.MutationResult<RemoveFolderMutation>;
export type RemoveFolderMutationOptions = Apollo.BaseMutationOptions<
  RemoveFolderMutation,
  RemoveFolderMutationVariables
>;
export const CreateFolderDocument = gql`
  query createFolder($folderName: String!) {
    createFolder(folderName: $folderName) {
      success
      path
      name
    }
  }
`;

/**
 * __useCreateFolderQuery__
 *
 * To run a query within a React component, call `useCreateFolderQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateFolderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateFolderQuery({
 *   variables: {
 *      folderName: // value for 'folderName'
 *   },
 * });
 */
export function useCreateFolderQuery(
  baseOptions: Apollo.QueryHookOptions<
    CreateFolderQuery,
    CreateFolderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CreateFolderQuery, CreateFolderQueryVariables>(
    CreateFolderDocument,
    options
  );
}
export function useCreateFolderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CreateFolderQuery,
    CreateFolderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CreateFolderQuery, CreateFolderQueryVariables>(
    CreateFolderDocument,
    options
  );
}
export type CreateFolderQueryHookResult = ReturnType<
  typeof useCreateFolderQuery
>;
export type CreateFolderLazyQueryHookResult = ReturnType<
  typeof useCreateFolderLazyQuery
>;
export type CreateFolderQueryResult = Apollo.QueryResult<
  CreateFolderQuery,
  CreateFolderQueryVariables
>;
export const RemoveMemberDocument = gql`
  mutation removeMember($id: String!) {
    removeMember(id: $id)
  }
`;
export type RemoveMemberMutationFn = Apollo.MutationFunction<
  RemoveMemberMutation,
  RemoveMemberMutationVariables
>;

/**
 * __useRemoveMemberMutation__
 *
 * To run a mutation, you first call `useRemoveMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMemberMutation, { data, loading, error }] = useRemoveMemberMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveMemberMutation,
    RemoveMemberMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveMemberMutation,
    RemoveMemberMutationVariables
  >(RemoveMemberDocument, options);
}
export type RemoveMemberMutationHookResult = ReturnType<
  typeof useRemoveMemberMutation
>;
export type RemoveMemberMutationResult =
  Apollo.MutationResult<RemoveMemberMutation>;
export type RemoveMemberMutationOptions = Apollo.BaseMutationOptions<
  RemoveMemberMutation,
  RemoveMemberMutationVariables
>;
export const UpdateMemberDocument = gql`
  mutation updateMember($data: UpdateMemberInput!) {
    updateMember(data: $data) {
      firstName
      lastName
      role
      company
      companyLink
      isHidden
      memberCategory
      shortDesc
      longDesc
      imgUrl
      logos
      isBoard
    }
  }
`;
export type UpdateMemberMutationFn = Apollo.MutationFunction<
  UpdateMemberMutation,
  UpdateMemberMutationVariables
>;

/**
 * __useUpdateMemberMutation__
 *
 * To run a mutation, you first call `useUpdateMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberMutation, { data, loading, error }] = useUpdateMemberMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMemberMutation,
    UpdateMemberMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMemberMutation,
    UpdateMemberMutationVariables
  >(UpdateMemberDocument, options);
}
export type UpdateMemberMutationHookResult = ReturnType<
  typeof useUpdateMemberMutation
>;
export type UpdateMemberMutationResult =
  Apollo.MutationResult<UpdateMemberMutation>;
export type UpdateMemberMutationOptions = Apollo.BaseMutationOptions<
  UpdateMemberMutation,
  UpdateMemberMutationVariables
>;
export const GetAllMembersDocument = gql`
  query getAllMembers($slug: String!) {
    getAllMembers(slug: $slug) {
      id
      firstName
      lastName
      role
      company
      companyLink
      isHidden
      memberCategory
      shortDesc
      longDesc
      imgUrl
      logos
      isBoard
    }
  }
`;

/**
 * __useGetAllMembersQuery__
 *
 * To run a query within a React component, call `useGetAllMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllMembersQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetAllMembersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllMembersQuery,
    GetAllMembersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllMembersQuery, GetAllMembersQueryVariables>(
    GetAllMembersDocument,
    options
  );
}
export function useGetAllMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllMembersQuery,
    GetAllMembersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllMembersQuery, GetAllMembersQueryVariables>(
    GetAllMembersDocument,
    options
  );
}
export type GetAllMembersQueryHookResult = ReturnType<
  typeof useGetAllMembersQuery
>;
export type GetAllMembersLazyQueryHookResult = ReturnType<
  typeof useGetAllMembersLazyQuery
>;
export type GetAllMembersQueryResult = Apollo.QueryResult<
  GetAllMembersQuery,
  GetAllMembersQueryVariables
>;
export const AddNewPollDocument = gql`
  mutation addNewPoll($data: AddNewPollInput!) {
    addNewPoll(data: $data) {
      slug
    }
  }
`;
export type AddNewPollMutationFn = Apollo.MutationFunction<
  AddNewPollMutation,
  AddNewPollMutationVariables
>;

/**
 * __useAddNewPollMutation__
 *
 * To run a mutation, you first call `useAddNewPollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNewPollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNewPollMutation, { data, loading, error }] = useAddNewPollMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddNewPollMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddNewPollMutation,
    AddNewPollMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddNewPollMutation, AddNewPollMutationVariables>(
    AddNewPollDocument,
    options
  );
}
export type AddNewPollMutationHookResult = ReturnType<
  typeof useAddNewPollMutation
>;
export type AddNewPollMutationResult =
  Apollo.MutationResult<AddNewPollMutation>;
export type AddNewPollMutationOptions = Apollo.BaseMutationOptions<
  AddNewPollMutation,
  AddNewPollMutationVariables
>;
export const DraftPollDocument = gql`
  mutation draftPoll($pollId: String!) {
    draftPoll(pollId: $pollId)
  }
`;
export type DraftPollMutationFn = Apollo.MutationFunction<
  DraftPollMutation,
  DraftPollMutationVariables
>;

/**
 * __useDraftPollMutation__
 *
 * To run a mutation, you first call `useDraftPollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDraftPollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [draftPollMutation, { data, loading, error }] = useDraftPollMutation({
 *   variables: {
 *      pollId: // value for 'pollId'
 *   },
 * });
 */
export function useDraftPollMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DraftPollMutation,
    DraftPollMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DraftPollMutation, DraftPollMutationVariables>(
    DraftPollDocument,
    options
  );
}
export type DraftPollMutationHookResult = ReturnType<
  typeof useDraftPollMutation
>;
export type DraftPollMutationResult = Apollo.MutationResult<DraftPollMutation>;
export type DraftPollMutationOptions = Apollo.BaseMutationOptions<
  DraftPollMutation,
  DraftPollMutationVariables
>;
export const PublishPollDocument = gql`
  mutation publishPoll($pollId: String!) {
    publishPoll(pollId: $pollId)
  }
`;
export type PublishPollMutationFn = Apollo.MutationFunction<
  PublishPollMutation,
  PublishPollMutationVariables
>;

/**
 * __usePublishPollMutation__
 *
 * To run a mutation, you first call `usePublishPollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishPollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishPollMutation, { data, loading, error }] = usePublishPollMutation({
 *   variables: {
 *      pollId: // value for 'pollId'
 *   },
 * });
 */
export function usePublishPollMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PublishPollMutation,
    PublishPollMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PublishPollMutation, PublishPollMutationVariables>(
    PublishPollDocument,
    options
  );
}
export type PublishPollMutationHookResult = ReturnType<
  typeof usePublishPollMutation
>;
export type PublishPollMutationResult =
  Apollo.MutationResult<PublishPollMutation>;
export type PublishPollMutationOptions = Apollo.BaseMutationOptions<
  PublishPollMutation,
  PublishPollMutationVariables
>;
export const GetAllPollsDocument = gql`
  query getAllPolls {
    getAllPolls {
      id
      name
      imgSrc
      desc
      slug
      owner {
        firstName
        lastName
      }
      isPublished
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetAllPollsQuery__
 *
 * To run a query within a React component, call `useGetAllPollsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPollsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPollsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllPollsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllPollsQuery,
    GetAllPollsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllPollsQuery, GetAllPollsQueryVariables>(
    GetAllPollsDocument,
    options
  );
}
export function useGetAllPollsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllPollsQuery,
    GetAllPollsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllPollsQuery, GetAllPollsQueryVariables>(
    GetAllPollsDocument,
    options
  );
}
export type GetAllPollsQueryHookResult = ReturnType<typeof useGetAllPollsQuery>;
export type GetAllPollsLazyQueryHookResult = ReturnType<
  typeof useGetAllPollsLazyQuery
>;
export type GetAllPollsQueryResult = Apollo.QueryResult<
  GetAllPollsQuery,
  GetAllPollsQueryVariables
>;
export const GetPollDocument = gql`
  query getPoll($slug: String!) {
    getPoll(slug: $slug) {
      id
      name
      slug
      isPublished
      questions {
        id
        questionName
        typeAnswer
        isRequired
        answers {
          id
          name
          value
        }
      }
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetPollQuery__
 *
 * To run a query within a React component, call `useGetPollQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPollQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPollQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetPollQuery(
  baseOptions: Apollo.QueryHookOptions<GetPollQuery, GetPollQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPollQuery, GetPollQueryVariables>(
    GetPollDocument,
    options
  );
}
export function useGetPollLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPollQuery, GetPollQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPollQuery, GetPollQueryVariables>(
    GetPollDocument,
    options
  );
}
export type GetPollQueryHookResult = ReturnType<typeof useGetPollQuery>;
export type GetPollLazyQueryHookResult = ReturnType<typeof useGetPollLazyQuery>;
export type GetPollQueryResult = Apollo.QueryResult<
  GetPollQuery,
  GetPollQueryVariables
>;
export const RemoveQuestionDocument = gql`
  mutation removeQuestion($questionId: String!) {
    removeQuestion(questionId: $questionId)
  }
`;
export type RemoveQuestionMutationFn = Apollo.MutationFunction<
  RemoveQuestionMutation,
  RemoveQuestionMutationVariables
>;

/**
 * __useRemoveQuestionMutation__
 *
 * To run a mutation, you first call `useRemoveQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeQuestionMutation, { data, loading, error }] = useRemoveQuestionMutation({
 *   variables: {
 *      questionId: // value for 'questionId'
 *   },
 * });
 */
export function useRemoveQuestionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveQuestionMutation,
    RemoveQuestionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveQuestionMutation,
    RemoveQuestionMutationVariables
  >(RemoveQuestionDocument, options);
}
export type RemoveQuestionMutationHookResult = ReturnType<
  typeof useRemoveQuestionMutation
>;
export type RemoveQuestionMutationResult =
  Apollo.MutationResult<RemoveQuestionMutation>;
export type RemoveQuestionMutationOptions = Apollo.BaseMutationOptions<
  RemoveQuestionMutation,
  RemoveQuestionMutationVariables
>;
export const UpdateQuestionDocument = gql`
  mutation updateQuestion($data: updateQuestionInput!) {
    updateQuestion(data: $data) {
      id
    }
  }
`;
export type UpdateQuestionMutationFn = Apollo.MutationFunction<
  UpdateQuestionMutation,
  UpdateQuestionMutationVariables
>;

/**
 * __useUpdateQuestionMutation__
 *
 * To run a mutation, you first call `useUpdateQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuestionMutation, { data, loading, error }] = useUpdateQuestionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateQuestionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateQuestionMutation,
    UpdateQuestionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateQuestionMutation,
    UpdateQuestionMutationVariables
  >(UpdateQuestionDocument, options);
}
export type UpdateQuestionMutationHookResult = ReturnType<
  typeof useUpdateQuestionMutation
>;
export type UpdateQuestionMutationResult =
  Apollo.MutationResult<UpdateQuestionMutation>;
export type UpdateQuestionMutationOptions = Apollo.BaseMutationOptions<
  UpdateQuestionMutation,
  UpdateQuestionMutationVariables
>;
export const ChangeUserStatusDocument = gql`
  mutation changeUserStatus($email: String!) {
    changeUserStatus(email: $email)
  }
`;
export type ChangeUserStatusMutationFn = Apollo.MutationFunction<
  ChangeUserStatusMutation,
  ChangeUserStatusMutationVariables
>;

/**
 * __useChangeUserStatusMutation__
 *
 * To run a mutation, you first call `useChangeUserStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUserStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUserStatusMutation, { data, loading, error }] = useChangeUserStatusMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useChangeUserStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeUserStatusMutation,
    ChangeUserStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangeUserStatusMutation,
    ChangeUserStatusMutationVariables
  >(ChangeUserStatusDocument, options);
}
export type ChangeUserStatusMutationHookResult = ReturnType<
  typeof useChangeUserStatusMutation
>;
export type ChangeUserStatusMutationResult =
  Apollo.MutationResult<ChangeUserStatusMutation>;
export type ChangeUserStatusMutationOptions = Apollo.BaseMutationOptions<
  ChangeUserStatusMutation,
  ChangeUserStatusMutationVariables
>;
export const LoginDocument = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      firstName
      lastName
      email
    }
  }
`;
export type LoginMutationFn = Apollo.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginMutation,
    LoginMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    options
  );
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
export const LogoutDocument = gql`
  mutation logout {
    logout
  }
`;
export type LogoutMutationFn = Apollo.MutationFunction<
  LogoutMutation,
  LogoutMutationVariables
>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LogoutMutation,
    LogoutMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(
    LogoutDocument,
    options
  );
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<
  LogoutMutation,
  LogoutMutationVariables
>;
export const GetAllUsersDocument = gql`
  query getAllUsers {
    getAllUsers(order: "DESC") {
      id
      firstName
      lastName
      phone
      email
      status
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetAllUsersQuery__
 *
 * To run a query within a React component, call `useGetAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllUsersQuery,
    GetAllUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(
    GetAllUsersDocument,
    options
  );
}
export function useGetAllUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllUsersQuery,
    GetAllUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(
    GetAllUsersDocument,
    options
  );
}
export type GetAllUsersQueryHookResult = ReturnType<typeof useGetAllUsersQuery>;
export type GetAllUsersLazyQueryHookResult = ReturnType<
  typeof useGetAllUsersLazyQuery
>;
export type GetAllUsersQueryResult = Apollo.QueryResult<
  GetAllUsersQuery,
  GetAllUsersQueryVariables
>;
export const MeDocument = gql`
  query Me {
    me {
      firstName
      lastName
      email
      phone
    }
  }
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(
  baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const GetAllVotersDocument = gql`
  query getAllVoters {
    getAllVoters(order: "DESC") {
      firstName
      lastName
      email
      votes {
        id
        voterId
        status
        poll {
          slug
        }
      }
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetAllVotersQuery__
 *
 * To run a query within a React component, call `useGetAllVotersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllVotersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllVotersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllVotersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllVotersQuery,
    GetAllVotersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllVotersQuery, GetAllVotersQueryVariables>(
    GetAllVotersDocument,
    options
  );
}
export function useGetAllVotersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllVotersQuery,
    GetAllVotersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllVotersQuery, GetAllVotersQueryVariables>(
    GetAllVotersDocument,
    options
  );
}
export type GetAllVotersQueryHookResult = ReturnType<
  typeof useGetAllVotersQuery
>;
export type GetAllVotersLazyQueryHookResult = ReturnType<
  typeof useGetAllVotersLazyQuery
>;
export type GetAllVotersQueryResult = Apollo.QueryResult<
  GetAllVotersQuery,
  GetAllVotersQueryVariables
>;
