import React, { FC } from 'react';
import { CSVLink } from 'react-csv';
import { Link } from 'react-router-dom';
import Sidebar from '../../components/Sidebar/Sidebar';
import { useGetAllVotersQuery } from '../../generated/apolloComponents';

const Voters: FC = () => {
  const { data: voters } = useGetAllVotersQuery();

  // const { slug } = useParams<Params>();
  // const { data: poll, loading } = useGetPollQuery({
  //   variables: {
  //     slug: String(slug),
  //   },
  // });
  return (
    <Sidebar>
      <div>
        <h1 className="text-3xl font-bold">Потребители</h1>
        <div className="my-2 flex sm:flex-row flex-col gap-4">
          <CSVLink
            className="bg-primaryBlack text-white px-4 py-2 rounded"
            data={
              voters?.getAllVoters.map((voter) => ({
                name: `${voter.firstName} ${voter.lastName}`,
                email: voter.email,
              })) || []
            }
            headers={[
              { label: 'Name', key: 'name' },
              { label: 'Email', key: 'email' },
            ]}
            filename="all_users.csv"
          >
            Експортирай всички потребители
          </CSVLink>
          <CSVLink
            className="bg-primaryBlack text-white px-4 py-2 rounded"
            data={
              voters?.getAllVoters.map((voter) => ({
                email: voter.email,
              })) || []
            }
            headers={[{ label: 'Email', key: 'email' }]}
            filename="all_emails.csv"
          >
            Експортирай всички имеили
          </CSVLink>
        </div>
        <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
          <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
            <table className="min-w-full leading-normal">
              <thead>
                <tr>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Имена
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Имейл
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Анкети
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Дата и час
                  </th>
                </tr>
              </thead>
              <tbody>
                {voters?.getAllVoters.map((v, index) => {
                  console.log(v);
                  return (
                    <tr key={index} id={v.email}>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {v.firstName} {v.lastName}
                        </p>
                      </td>

                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {v.email}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        {Array.from(
                          new Set(v.votes.map((vote: any) => vote.poll.slug))
                        ).map((slug: unknown) => (
                          <Link
                            key={String(slug)}
                            to={`/poll-statistics/${String(slug)}?email=${
                              v.email
                            }`}
                            className="bg-black rounded-md text-white text-xs font-semibold px-2 py-1 mr-1 mb-2 inline-block"
                          >
                            {String(slug)
                              .replace(/-/g, ' ')
                              .replace(/\b\w/g, (c: string) => c.toUpperCase())}
                          </Link>
                        ))}
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap w-max flex-nowrap">
                          {new Date(v.createdAt).toLocaleString('bg-BG', {
                            month: 'short',
                            day: 'numeric',
                            year: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                          })}
                        </p>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default Voters;
